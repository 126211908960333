import {TableDefinition} from '../models';

export const CONTACT_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Contact.id',
    prop: 'id',
    visible: false,
    objectName: 'Contact',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Contact.firstName',
    prop: 'firstName',
    visible: true,
    objectName: 'Contact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.lastName',
    prop: 'lastName',
    visible: true,
    objectName: 'Contact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.email',
    prop: 'email',
    visible: true,
    objectName: 'Contact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.phone',
    prop: 'phone',
    visible: true,
    objectName: 'Contact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.phoneNumberStatus',
    prop: 'phoneNumberStatus',
    visible: true,
    objectName: 'Contact',
    searchFieldType: 'STRING',
  },
];


export const CONTACT_DATA_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Contact.id',
    prop: 'id',
    visible: false,
    objectName: 'Contact',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Contact.firstName',
    prop: 'firstName',
    visible: true,
    objectName: 'SegmentContact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.lastName',
    prop: 'lastName',
    visible: true,
    objectName: 'SegmentContact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.email',
    prop: 'email',
    visible: true,
    objectName: 'SegmentContact',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Contact.phone',
    prop: 'phone',
    visible: true,
    objectName: 'SegmentContact',
    searchFieldType: 'STRING',
  },
  // {
  //   key: 'API.Contact.phoneNumberStatus',
  //   prop: 'phoneNumberStatus',
  //   visible: true,
  //   objectName: 'SegmentContact',
  //   searchFieldType: 'STRING',
  // },
];

export const MESSAGE_CAMPAIGN_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.MessageCampaign.id',
    prop: 'id',
    visible: false,
    objectName: 'MessageCampaign',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.MessageCampaign.name',
    prop: 'name',
    visible: true,
    objectName: 'MessageCampaign',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.MessageCampaign.typeLabel',
    prop: 'type',
    visible: true,
    valueKeyPrefix: 'API.MessageCampaign.type',
  },
  { key: 'API.MessageCampaign.language', prop: 'language', visible: true },
  {
    key: 'API.MessageCampaign.statistics.numberOfOrderedMessages',
    prop: 'ordered',
    visible: true,
    sortDisabled: true,
  },
  {
    key: 'API.MessageCampaign.statistics.numberOfSentMessages',
    prop: 'sent',
    visible: true,
    sortDisabled: true,
  },
  {
    key: 'API.MessageCampaign.statistics.numberOfOpenedMessages',
    prop: 'opened',
    visible: true,
    sortDisabled: true,
  },
  {
    key: 'API.MessageCampaign.statistics.numberOfCancelledMessages',
    prop: 'canceled',
    visible: true,
    sortDisabled: true,
  },
  {
    key: 'API.MessageCampaign.dateStartUTC',
    prop: 'dateStartUTC',
    visible: true,
    dateFormat: 'short',
  },
  {
    key: 'API.MessageCampaign.lastModifiedAtUTC',
    prop: 'lastModifiedAtUTC',
    visible: true,
    dateFormat: 'short',
  },
  {
    key: 'API.MessageCampaign.status',
    prop: 'status',
    visible: true,
    valueKeyPrefix: 'API.MessageCampaign.status',
  },
];

export const MEETING_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Meeting.id',
    prop: 'id',
    visible: false,
    objectName: 'Meeting',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Meeting.title',
    prop: 'title',
    visible: true,
    objectName: 'Meeting',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Presentation.name',
    prop: 'presentation',
    visible: true,
    sortDisabled: true,
    objectName: 'Presentation',
    objectField: 'name',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Meeting.startTimeUTC',
    prop: 'startTimeUTC',
    visible: true,
    dateFormat: 'short',
  },
  {
    key: 'API.Meeting.endTimeUTC',
    prop: 'endTimeUTC',
    visible: true,
    dateFormat: 'short',
  },
  { key: 'API.Meeting.duration', prop: 'duration', visible: true },
  {
    key: 'API.Moderator.fullName',
    prop: 'moderator',
    visible: true,
    sortDisabled: true,
    objectName: 'Representative',
    objectField: 'lastName',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Meeting.participantListTotal',
    prop: 'participantListTotal',
    visible: true,
    sortDisabled: true,
  },
];

export const GROUP_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Group.id',
    prop: 'id',
    visible: false,
    objectName: 'Group',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Group.name',
    prop: 'name',
    visible: true,
    objectName: 'Group',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Group.contactListTotal',
    prop: 'contactListTotal',
    visible: true,
    sortDisabled: true,
  },
  {
    key: 'API.Group.representativeListTotal',
    prop: 'representativeListTotal',
    visible: true,
    sortDisabled: true,
  },
];

export const LEAD_TABLE_DEFINITION: TableDefinition = [
  { key: 'API.Lead.id', prop: 'id', visible: false, objectName: 'Lead', searchFieldType: 'NUMBER' },
  {
    key: 'API.Lead.firstName',
    prop: 'firstName',
    visible: true,
    objectName: 'Lead',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Lead.lastName',
    prop: 'lastName',
    visible: true,
    objectName: 'Lead',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Lead.email',
    prop: 'email',
    visible: true,
    objectName: 'Lead',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Lead.phone',
    prop: 'phone',
    visible: true,
    objectName: 'Lead',
    searchFieldType: 'STRING',
  },
];

export const WORKPLACE_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Workplace.id',
    prop: 'id',
    visible: false,
    objectName: 'Workplace',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Workplace.name',
    prop: 'name',
    visible: true,
    objectName: 'Workplace',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Workplace.ward',
    prop: 'ward',
    visible: true,
    objectName: 'Workplace',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Workplace.street',
    prop: 'street',
    visible: true,
    objectName: 'Workplace',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Workplace.postalCode',
    prop: 'postalCode',
    visible: true,
    objectName: 'Workplace',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Workplace.city',
    prop: 'city',
    visible: true,
    objectName: 'Workplace',
    searchFieldType: 'STRING',
  },
];

export const SPECIALIZATION_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Specialization.id',
    prop: 'id',
    visible: false,
    objectName: 'Specialization',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Specialization.name',
    prop: 'name',
    visible: true,
    objectName: 'Specialization',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Specialization.contactListTotal',
    prop: 'contactListTotal',
    visible: true,
    sortDisabled: true,
  },
];

export const SEGMENT_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Segment.id',
    prop: 'id',
    visible: false,
    objectName: 'Segment',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Segment.name',
    prop: 'name',
    visible: true,
    objectName: 'Segment',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Segment.contacts',
    prop: 'numberOfUsers',
    visible: true,
    objectName: 'Segment',
    sortDisabled: true,
  },
  {
    key: 'API.Segment.status',
    prop: 'activeText',
    visible: true,
    objectName: 'Segment',
    sortDisabled: true,
  },

];

export const BATCH_JOB_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.BatchJob.id',
    prop: 'id',
    visible: false,
    objectName: 'BatchJob',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.BatchJob.startDate',
    prop: 'startedAt',
    visible: true,
    dateFormat: 'y-MM-dd HH:mm',
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.name',
    prop: 'name',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.startedBy',
    prop: 'userName',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.progress',
    prop: 'progress',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.BatchJob.status.name',
    prop: 'status',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
];



export const CUSTOM_FIELD_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.CustomField.id',
    prop: 'id',
    visible: false,
    objectName: 'CustomFieldDefinition',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.CustomField.name',
    prop: 'name',
    visible: true,
    objectName: 'CustomFieldDefinition',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.CustomField.label',
    prop: 'label',
    visible: true,
    objectName: 'CustomFieldDefinition',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.CustomField.type',
    prop: 'type',
    visible: true,
    objectName: 'CustomFieldDefinition',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.CustomField.active',
    prop: 'isactive',
    visible: true,
    objectName: 'CustomFieldDefinition',
    searchFieldType: 'NUMBER',
  }
];

export const REPRESENTATIVE_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.Representative.id',
    prop: 'id',
    visible: false,
    objectName: 'Representative',
    searchFieldType: 'NUMBER',
  },
  {
    key: 'API.Representative.firstName',
    prop: 'firstName',
    visible: true,
    objectName: 'Representative',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.Representative.lastName',
    prop: 'lastName',
    visible: true,
    objectName: 'Representative',
    searchFieldType: 'STRING',
  }
];

export const BILLING_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'billing.billingList.tableColumn.id',
    prop: 'id',
    visible: false,
    objectName: 'Billing',
    searchFieldType: 'NUMBER',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.year',
    prop: 'dateFrom',
    dateFormat:'Y',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.month',
    prop: 'dateFromMonth',
    dateFormat:'MMMM',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.status',
    prop: 'status',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled:true,
    valueKeyPrefix:'billing.billingList.rowValue.status'
  },
  {
    key: 'billing.billingList.tableColumn.totalPayment',
    prop: 'totalCostFormatted',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.updatedAt',
    prop: 'lastModifiedDate',
    dateFormat:'short',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.updatedBy',
    prop: 'lastModifiedBy',
    visible: true,
    objectName: 'Billing',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
];


export const BILLING_DETAIL_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'billing.billingDetail.tableColumn.id',
    prop: 'id',
    visible: false,
    objectName: 'BillingDetail',
    searchFieldType: 'NUMBER',
    sortDisabled:true
  },
  {
    key: 'billing.billingDetail.tableColumn.element',
    prop: 'packageType',
    visible: true,
    objectName: 'BillingDetail',
    searchFieldType: 'STRING',
    sortDisabled:true,
    valueKeyPrefix:'billing.package'
  },
  // {
  //   key: 'billing.billingDetail.tableColumn.planName',
  //   prop: 'planName',
  //   visible: true,
  //   objectName: 'BillingDetail',
  //   searchFieldType: 'STRING',
  //   sortDisabled:true
  // },

  {
    key: 'billing.billingDetail.tableColumn.planCost',
    prop: 'planCost',
    visible: true,
    objectName: 'BillingDetail',
    searchFieldType: 'NUMBER',
    sortDisabled:true
  },
  {
    key: 'billing.billingDetail.tableColumn.usage',
    prop: 'usage',
    visible: true,
    objectName: 'BillingDetail',
    searchFieldType: 'NUMBER',
    sortDisabled:true
  },
  {
    key: 'billing.billingDetail.tableColumn.costPerUse',
    prop: 'costPerUse',
    visible: true,
    objectName: 'BillingDetail',
    searchFieldType: 'NUMBER',
    sortDisabled:true
  },
  {
    key: 'billing.billingDetail.tableColumn.additionalCharges',
    prop: 'additionalCharges',
    visible: true,
    objectName: 'BillingDetail',
    sortDisabled:true
  },
  {
    key: 'billing.billingDetail.tableColumn.due',
    prop: 'due',
    visible: true,
    objectName: 'BillingDetail',
    sortDisabled:true
  },
];


export const BILLING_PLAN_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'billing.billingList.tableColumn.id',
    prop: 'id',
    visible: false,
    objectName: 'BillingPlan',
    searchFieldType: 'NUMBER',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.year',
    prop: 'dateFrom',
    dateFormat:'Y',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.month',
    prop: 'dateFromMonth',
    dateFormat:'MMMM',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.day',
    prop: 'dateFromDay',
    dateFormat:'dd',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.status',
    prop: 'status',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled:true,
    valueKeyPrefix:'billing.billingList.rowValue.status'
  },
  {
    key: 'billing.billingList.tableColumn.billingPeriod',
    prop: 'billingPeriod',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.updatedAt',
    prop: 'lastModifiedDate',
    dateFormat:'short',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
  {
    key: 'billing.billingList.tableColumn.updatedBy',
    prop: 'lastModifiedBy',
    visible: true,
    objectName: 'BillingPlan',
    searchFieldType: 'STRING',
    sortDisabled:true
  },
];
